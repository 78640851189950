.parentAirtimeTopup {
    height: 80vh;
    margin-top: 5%;
    display: flex;
    margin-bottom: 10%;
    align-items: center;
    justify-content: center;
    /* background-color: antiquewhite; */
   
}
#staticBackdropLabel{
    color: green;
}
.setDivSENdtop{
    /* background-color: aqua; */
    display: flex;
    justify-content: space-around;
}
.rechargewithVite{
    background-color:#12258F;
}
.insTred{
    color: red;
}

.secondLayerTopUp {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    padding: 20px;
    gap: 20px;
    width: 80%;
    height: 100%;
  
   
    /* background-color: rgb(48, 48, 102); */
}

.topupIMGDiv {
    width: 25%;
    height: 75%;
    border-radius: 20px;
    /* background-color: aliceblue; */
}

.topupIMGDiv img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.topUpMainDiv {
    height: 75%;
    width: 60%;
    padding: 30px;
    background-color: #FFFF;
    /* box-shadow: darkgray 1px 1px 5px 1px; */
    border-radius: 20px;
}
.payNowDivBTn{
    justify-content: end;
    text-align: end;
    padding: 8px;
}

.payNowDiv {
    /* background-color: aqua; */
    height: 20%;
    /* margin-top: 10%; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.topUpMainDiv h3 {
    margin-bottom: 3%;
    background-color: #000000;
    color: white;
    text-align: center;
}





.container-con-Contain {
    display: grid;
    place-items: center;
    
  }
  
  .btn-container {
    --btn-container-bg: #2BBCF1;
   
  }
  
  .active,
  .side-btn-container input[type="radio"]:checked + label {
    --active-btn-bg: #fffdfa;
     
  }
  
  .inactive,
  .labVoice {
    --inactive-btn-text: white;
    font-weight: 700;
  }
  

  
  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    background-color: var(--btn-container-bg);
    border: var(--btn-container-border) 1px solid;
    width: 80%;
    height: 58px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
 
  
  .active, .inactive {
    width: 45.5%;
    height: 60%;
    padding: 10px 20px;
    border: 0px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .active {
    background-color: var(--active-btn-bg);
    box-shadow: 0 0 10px 1px var(--active-btn-box-shadow);
    font-weight: bold;
    color: var(--active-btn-text);
  }
  
  .inactive {
    background-color: transparent;
    color: var(--inactive-btn-text);
    text-decoration: none;
  }
  

  
  .side-btn-container input[type="radio"] {
    visibility: hidden;
    height: 0;
    width: 0;
    margin: 0;
  }
  
  .labVoice {
    width: 45.5%;
  
    padding:  10px;
    border: 0px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    margin-top:1%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--inactive-btn-text);
  }
  
  .side-btn-container input[type="radio"]:checked + label {
    background-color: var(--active-btn-bg);
    box-shadow: 0 0 10px 1px var(--active-btn-box-shadow);
    font-weight: bold;
    color: var(--active-btn-text);
  }





@media screen and (max-width: 800px) {

    .parentAirtimeTopup {
        margin-bottom: 30%;
    }

    .topupIMGDiv {
        display: none;
    }

    .topUpMainDiv {
        width: 100%;
        margin-top: 5% !important;
    }

    .secondLayerTopUp {
        width: 100%;
        padding: 10px;
    }

    .topUpMainDiv h3 {
        font-size: 1.5em;
        text-align: center;
      
    }
    .getStartedBtn{
      
    }
    .payNowDiv{
        height: 15% !important;
    }
    .setDivSENdtop{
        gap: 5%;
        margin-top: 2%;
    }
    .btn-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 5px;
        background-color: var(--btn-container-bg);
        border: var(--btn-container-border) 1px solid;
        width: 100%;
        height: 58px;
        border-radius: 10px;
        margin-bottom: 20px;
      }
      
}