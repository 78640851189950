.parentPartnerDiv {
    font-family: Arial, Arial Nova, Gill Sans;
    margin-bottom: 5em;
    background-color: #F9FAFB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.partnerHerosection {
    height: 50em;
    /* Scales better with font size */
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
    margin-top: 3em;
    flex-wrap: wrap;
    /* Ensures responsiveness on smaller screens */
}

.partnerherotex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1.5em;
}

.herotitletext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.joinspan {
    font-size: 3.5em;
    font-weight: 900;
}

.mission {
  color: #2bbcf1;
  font-weight: bolder;
  text-transform: capitalize;
}

.doublespan {
    font-size: 3em;
    font-weight: 700;
}

.payspan {
    font-size: 2.5em;
    font-weight: 800;
}

.doublehus {
  color: #0f0f7b;
  font-weight: 800;
}

.partnerHeroBtn .linkBuuton {
    padding: 1em 1.2em;
    font-size: 1.2em;
    border-radius: 0.5em;
    cursor: pointer;
    box-shadow: rgb(38, 57, 77) 0px 0.8em 0.5em -0.5em;
    background: linear-gradient(to right, #5756A2, #2BBCF1);
    background-size: 200% 200%;
    color: white;
    border: none;
    animation: gradientAnimation 10s ease-in-out infinite;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    white-space: nowrap;
}

.partnerheroimg img {
    max-width: 100%;
    height: auto;
    border-radius: 0.5em;
}

@media (max-width: 86.7em) {


    .partnerheroimg {
        width: 50%;
    }

    .partnerHerosection {
        width: 90%;
    }
}

@media (max-width: 66.5em) {


    .partnerheroimg {
        width: 40%;
    }

    .partnerHerosection {
        width: 90%;
    }
}



@media (max-width: 60.06em) {


    .partnerHerosection {

        text-align: center;
        height: 30em;
        width: 90%;
    }

    .herotitletext {
        align-items: start;
    }

    .joinspan,
    .doublespan,
    .payspan {
        font-size: 2.5em;
    }

    .partnerHeroBtn .linkBuuton {
        font-size: 1em;
    }

    .partnerheroimg {
        width: 40%;
    }
}

@media (max-width: 47em) {
    .partnerheroimg {
        display: none;
    }

    .partnerHerosection {
        justify-content: center;
        align-items: center;
    }

    .partnerHeroBtn {
        /* background-color: #2BBCF1; */
        width: 100%;
    }

    .joinspan,
    .doublespan,
    .payspan {
        font-size: 2em;
    }
}
