



.button-55 {
 align-self: center;
 background-color: #fff;
 background-image: none;
 background-position: 0 90%;
 background-repeat: repeat no-repeat;
 background-size: 4px 3px;
 border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
 border-style: solid;
 border-width: 2px;
 box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
 box-sizing: border-box;
 color: #41403e;
 cursor: pointer;
 display: inline-block;
 font-family: Neucha, sans-serif;
 font-size: 1rem;
 line-height: 23px;
 outline: none;
 padding: .75rem;
 text-decoration: none;
 transition: all 235ms ease-in-out;
 border-bottom-left-radius: 15px 255px;
 border-bottom-right-radius: 225px 15px;
 border-top-left-radius: 255px 15px;
 border-top-right-radius: 15px 225px;
 user-select: none;
 -webkit-user-select: none;
 touch-action: manipulation;
}

.button-55:hover {
 box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
 transform: translate3d(0, 2px, 0);
}
.notFond_outofStock{
  background-color: #96d7f1;
  padding: 5px;
  font-weight: 600;
  margin-top: 1%;
}

.button-55:focus {
 box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}
.commercenav-container h3{
    margin-top: 5px;
    margin-left: 5%;
}
.name-ebeano-profile{
 margin-top: 5%;
 padding-left: 20px;
}

.main-eco-goods {
 max-width: 90%; 
  margin: 0 auto;
   margin-top: -7%;  
  justify-content: center !important;
  }
  
 .main-eco-goodsrelated{
   max-width: 90%; 
  margin: 0 auto;
  justify-content: center !important;
  
 }
 .pdetailsdet {
   color: black;
 }

.cards-eco {
  list-style: none;
   margin: 0;
   padding: 0;
   width:200px !important;
   height:200px !important ; 
   background-color: #0483a0;
 }
 
 .cards_item-eco {
   display: flex;
   padding: 1rem;
   margin-top: 6.8%;
   margin-left: 4%  ;
  
 }

 #cardsId{
   display: grid;
   grid-template-columns: auto auto auto ;
   background-color: white;
   padding: 0;
   /* width: 80%; */
   margin-top: 8% ;
   justify-content: center;
   box-sizing: border-box;
   box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
   border-color: white;
   list-style-type: none;
   justify-content: left !important;


 }
 .second-displayEcom{
   background-color: white;
   padding: 0;
   /* width: 80%; */
   margin-top: 1%;
   justify-content: center;
   box-sizing: border-box;
   box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
   border-color: white;
   /* padding-left: 5%;
   padding-right: 5%; */

 }


 .cardsId-Mimic{
   /* margin-top: 0% !important; */
   gap: 20px;
   display: flex;
   justify-content: center;
   
   padding: 15px;
   
 }
 .hot-deal-Prices{
   padding: 10px;
   background-color: #030334;
   color: white;
 }
 .headingss.colorChange{
      color: green;
 }


 .clip-star {
   background: darkgray;
   clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
   display: inline-block;
   height: 20px;
   width: 20px;
 }
 .flexDisplay_Image{
   display: flex;
  
 }
 
 
 
 .button-16 {
   background-color:#0D0D4A;
   border: 1px solid #f8f9fa;
   border-radius: 4px;
   color: #3c4043;
   cursor: pointer;
   font-family: arial,sans-serif;
   font-size: 14px;
   height: 36px;
   line-height: 27px;
   min-width: 54px;
   padding: 0 16px;
   text-align: center;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
   white-space: pre;
   margin-left: 60%;
   color: white;
 }
 
 .button-16:hover {
   border-color: #dadce0;
   box-shadow: rgba(0, 0, 0, .1) 0 1px 1px;
   color: white;
 }
 
 .button-16:focus {
   border-color: #4285f4;
   outline: none;
 }

 





 .abia-deal{
   /* position: absolute; */
   margin-top: 10% ;
    /* background-color: #054B05;  */
   margin-left: 5%;
 }
 .abia-deal p:hover{
   cursor: default;
   color: darkorange;

 }


 .card_image {
   position: relative;
   /* max-height: 270px; */
 }
 
 .card_image img {
   width: 400px;
   height: 300px;
   object-fit: cover;
   cursor: pointer;
   margin-left: 10% !important;
 }
 .card_image_main{
   width: 400px !important;
   height: 300px !important;
   object-fit: cover;
   cursor: pointer;
   margin-left: 10% !important;
 }
 .moreDetail_main{
   cursor: pointer;
   font-weight: bold;
 }
 
 .card_price {
   position: absolute;
   bottom: 8px;
   right: 8px;
   display: flex;
   justify-content: center;
   align-items: center;
   /* width: 45px;
   height: 45px; */
   border-radius: 0.25rem;
   background-color: #030334;
   font-size: 18px;
   font-weight: 700;
   padding: 5px;
   color: white;
 }
 .card_priceCat {
   position: absolute;
   /* bottom: 0; */
   top: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   /* width: 45px;
   height: 45px; */
   background-color: #030334c6;
   font-size: 10px;
   font-weight: lighter;
   padding: 5px;
   color: white;
 }
.card_price span {
   font-size: 12px;
   margin-top: -2px;
 }
 .card_price span:hover{
   color: black !important;
 }
 
 .note {
   position: absolute;
   top: 8px;
   left: 8px;
   padding: 4px 8px;
   border-radius: 0.25rem;
   background-color:darkorange;
   font-size: 14px;
   font-weight: 700;
 }
 .goodsdetails-container-flex{
   display: flex;
   gap: 3%;
   margin-top: 5%;
 }
 
 @media (min-width: 40rem) {
   .cards_item-eco {
     width: 50%;
   }
 }
 
 @media (min-width: 56rem) {
   .cards_item-eco{
     width: 33.3333%;
   }
 }

 @media (min-width: 40rem) {
   .cards_item-item {
     width: 50%;
   }
 }
 
 @media (min-width: 56rem) {
   .cards_item-item{
     width: 33.3333%;
   }
 }
 
 .card-eco {
   background-color: white;
   border-radius: 0.25rem;
   box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
   display: flex;
   flex-direction: column;
   overflow: hidden;
   width: 500px;
   height: 550px;
   margin-left: 7%;
 }
 
 .card_content {
   position: relative;
   padding: 16px 12px 32px 24px;
   margin: 16px 8px 8px 0;
   max-height: 290px;
   overflow-y: scroll;
 }
 
 .card_content::-webkit-scrollbar {
   width: 8px;
 }
 
 .card_content::-webkit-scrollbar-track {
   box-shadow: 0;
   border-radius: 0;
 }
 
 .card_content::-webkit-scrollbar-thumb {
   background: #3B3B61;
   border-radius: 15px;
 }
 
 .card_title {
   position: relative;
   margin: 0 0 24px;
   padding-bottom: 10px;
   text-align: center;
   font-size: 22px;
   font-weight: 700;
 }
 
 .card_title::after {
   position: absolute;
   display: block;
   width: 50px;
   height: 2px;
   bottom: 0;
   left: 50%;
   transform: translateX(-50%);
   background-color: #c89b3f;
   content: "";
 }
 
 .eco-goods-hr {
   margin: 24px auto;
   width: 50px;
   border-top: 2px solid #c89b3f;
 }
 
 .card_text p {
   margin: 0 0 24px;
   font-size: 14px;
   line-height: 1.5;
 }
 
 .card_text p:last-child {
   margin: 0;
 }

 /* footer style */
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
 .sec-footer {
   padding: 60px 0;
}

/* .sec-footer .section-title {
   text-align: center;
   color: #007b5e;
   margin-bottom: 50px;
   text-transform: uppercase;
} */
 


/* footer stlye */

footer {
   background: #16222A;
   background: -webkit-linear-gradient(59deg, #3A6073, #16222A);
   background: linear-gradient(59deg, #3A6073, #16222A);
   color: white;
   margin-top:100px;
 }
 
 footer a {
   color: #fff;
   font-size: 14px;
   transition-duration: 0.2s;
  
 }
 
 footer a:hover {
   color: #FA944B;
   text-decoration: none;
 }
 
 .copy {
   font-size: 12px;
   padding: 10px;
   border-top: 1px solid #FFFFFF;
 }
 
 .footer-middle {
   padding-top: 2em;
   color: white;
 }
 
 
 /*SOCİAL İCONS*/
 
 /* footer social icons */
 
 ul.social-network {
   list-style: none;
   display: inline;
   margin-left: 0 !important;
   padding: 0;
 }
 
 ul.social-network li {
   display: inline;
   margin: 0 5px;
 }
 
 
 /* footer social icons */
 
 .social-network a.icoFacebook:hover {
   background-color: #3B5998;
 }
 
 .social-network a.icoLinkedin:hover {
   background-color: #007bb7;
 }
 
 .social-network a.icoFacebook:hover i,
 .social-network a.icoLinkedin:hover i {
   color: #fff;
 }
 
 .social-network a.socialIcon:hover,
 .socialHoverClass {
   color: #44BCDD;
 }
 
 .social-circle li a {
   display: inline-block;
   position: relative;
   margin: 0 auto 0 auto;
   -moz-border-radius: 50%;
   -webkit-border-radius: 50%;
   border-radius: 50%;
   text-align: center;
   width: 30px;
   height: 30px;
   font-size: 15px;
 }
 
 .social-circle li i {
   margin: 0;
   line-height: 30px;
   text-align: center;
 }
 
 .social-circle li a:hover i,
 .triggeredHover {
   -moz-transform: rotate(360deg);
   -webkit-transform: rotate(360deg);
   -ms--transform: rotate(360deg);
   transform: rotate(360deg);
   -webkit-transition: all 0.2s;
   -moz-transition: all 0.2s;
   -o-transition: all 0.2s;
   -ms-transition: all 0.2s;
   transition: all 0.2s;
 }
 
 .social-circle i {
   color: #595959;
   -webkit-transition: all 0.8s;
   -moz-transition: all 0.8s;
   -o-transition: all 0.8s;
   -ms-transition: all 0.8s;
   transition: all 0.8s;
 }
 
 .social-network a {
   background-color: #F9F9F9;
 }
.container-footer{
   margin-left: 5%;
}

/* end */
.trending-head{
   margin-top: 2%;
   margin-left: 1%;
}
.linkselect{
   text-decoration: none;
   color: black;
}
.details-container{
display: flex;
justify-content: center;
gap: 2%;
margin-top: -5% !important;

}
.linkselect:hover{
 text-decoration: none;
 color: darkorange;
}


.details-image{
   width: 800px;
   height: 535px;
}
.sidedetails-container{
 box-sizing: border-box;
 box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
 padding: 10px;
 /* height: 450px; */
 justify-content: center;
 width: 300px;
 background-color: white;


}

.sidedetails-containerDetails{
 box-sizing: border-box;
 box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
 padding: 10px;
  height: 800px; 
 justify-content: center;
 width: 300px;
 background-color: white;
 margin-top: 0.5%;


}
.sidedetails-container p{
 margin-top: 5%;
}


.image-details-con{
   width: 800px;
   
   box-sizing: border-box;
   box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
   padding: 5px;
}
.handle-time{
  margin-top: 1%;
}
.second-downimg{
  width: 180px;
  height: 180px;
}
.secondimages-container{
display: grid;
grid-template-columns: auto auto auto auto;
margin-top: 2%;
gap: 10px;
}
.timedown-image-container{
 display: flex;
 gap: 5%;
 padding: 5px;
}
.product-detailhead{
 background-color: black;
 color: white !important;
 padding: 5px;
 color: white;
 text-align: center;
}
.product-detailcontainers{
 padding: 10px;
}
.button-6 {
 align-items: center;
 background-color:#030334;
 border: 1px solid rgba(0, 0, 0, 0.1);
 border-radius: .25rem;
 box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
 box-sizing: border-box;
 color: white;
 cursor: pointer;
 display: inline-flex;
 font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
 font-size: 16px;
 font-weight: 600;
 justify-content: center;
 line-height: 1.25;
 margin: 0;
 min-height: 3rem;
 padding: calc(.875rem - 1px) calc(1.5rem - 1px);
 position: relative;
 text-decoration: none;
 transition: all 250ms;
 user-select: none;
 -webkit-user-select: none;
 touch-action: manipulation;
 vertical-align: baseline;
 width: 100% !important;
}

.button-6:hover,
.button-6:focus {
 border-color: rgba(0, 0, 0, 0.15);
 box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
 color: white;
}

.button-6:hover {
 transform: translateY(-1px);
 border-radius: 2px;
}
.hoverStyleImage:hover{
       border: solid darkorange 1px;
}

.button-6:active {
 background-color: #F0F0F1;
 border-color: rgba(0, 0, 0, 0.15);
 box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
 color: rgba(0, 0, 0, 0.65);
 transform: translateY(0);
}
#showcontact-btn{
background-color: whitesmoke;
color: black;
 margin-top: 5%;
}

.border-line{
 border: white solid 1px;
 

}
.oursolutions-line{
 border: #606060 solid;
 width: 5%;
 text-align: center;
 margin-left:48% ;
}
.our-solution-text{
 text-align: center;
 justify-content: center;
 margin-top: 6%;
}
.body3-container-all{
justify-content: center;
text-align: center; 
padding: 10px;
display: flex;
margin-top: 4%;

}

.body3-main{
 display: flex;
 gap: 8%;
}
.body3-img1{
 width: 500px;
}
.button-body3-check {
 border-radius: .25rem;
 text-transform: uppercase;
 font-style: normal;
 font-weight: 400;
 padding-left: 25px;
 padding-right: 25px;
 color: #fff;
 -webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
 clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
 height: 40px;
 font-size: 0.7rem;
 line-height: 14px;
 letter-spacing: 1.2px;
 transition: .2s .1s;
 background-image: linear-gradient(90deg,#062774 ,#030334);
 border: 0 solid;
 overflow: hidden;
 text-align: right;
}

.button-body3-check:hover {
 cursor: pointer;
 transition: all .3s ease-in;
 padding-right: 30px;
 padding-left: 30px;
}
.body3-text-div{
 margin-top: 4%;

  width: 500px;
  font-size: 22px;
}
.tittle-text{
 font-family: 'Alfa Slab One', cursive;
  /* font-family: 'Lobster', cursive;
  font-family: 'Russo One', sans-serif; */
  font-weight: bold;
  font-size: 33px;
}
.body3-image-div{
 margin-top: 5%;
}
.navlink-link{

 width: 350px;
 text-decoration: none;
 color: white;
}
.navlink-link:hover{
 text-decoration: none;
 color: whitesmoke;
}
.booking-form-container{
 z-index: 50;
 cursor: text;

}

.booking-container{
 display: flex;
 justify-content: center;
 gap: 2%;
 padding-top: 2%;

}
.bookingform-main{
 width: 800px;
 border: 1px solid rgba(0, 0, 0, 0.1);
 border-radius: .25rem;
 box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
 box-sizing: border-box;
 color: white;
 padding: 10px;
 background-color: white;
}
#confirmorder{
 background-color: rgb(5, 75, 5);
 width: 100% !important;
}
.lb{
 color: black !important;
 
}
.filld-form{
 background-color: rgba(0, 0, 0, 0.133);
 text-align: center;
 width: 100%;
 height: 50px;
 padding: 3px;
 margin-top: 2%;
 color: #030334;
 background-image: url("https://img.freepik.com/free-vector/yellow-blue-background_1189-288.jpg?w=740&t=st=1702553351~exp=1702553951~hmac=4c7c4fc26132594ebcb25131c79e6471f005eb1576500b1a7bd83044b4efb45e");
 color: white;

}
#conorder{
 background-color: #030334;
 z-index: 50 !important;
 justify-content: end !important;

}

.byline{
 text-align:center;
 font-size:18px;
}
.byline a{
 text-decoration:none;
 color: #ce0000;
}


.searchbox{
   background-color:#fffbf8;
    padding:10px; 
   width:40%;
   /* margin:100px auto; */
   -webkit-box-sizing:border-box;
   -moz-box-sizing:border-box;
   box-sizing:border-box;
   border-radius:6px;
   -webkit-box-shadow:
       0 2px 4px 0 rgba(72, 72, 72, 0.83),
       0 10px 15px 0 rgba(126, 126, 126, 0.12),
       0 -2px 6px 1px rgba(199, 199, 199, 0.55) inset, 
       0 2px 4px 2px rgba(255, 255, 255, 0.83) inset;
   -moz-box-shadow: 
       0 2px 4px 0 rgba(72, 72, 72, 0.83),
       0 10px 15px 0 rgba(126, 126, 126, 0.12),
       0 -2px 6px 1px rgba(199, 199, 199, 0.55) inset, 
       0 2px 4px 2px rgba(255, 255, 255, 0.83) inset;
   box-shadow: 
       0 2px 4px 0 rgba(72, 72, 72, 0.83),
       0 10px 15px 0 rgba(126, 126, 126, 0.12),
       0 -2px 6px 1px rgba(199, 199, 199, 0.55) inset, 
       0 2px 4px 2px rgba(255, 255, 255, 0.83) inset;
        margin-left: 28.5%; 
       
}
.input-search[type="search"]{
   width:90%;
   height:50px;
   padding-left:15px;
   border-radius:6px;
   border:none;
   color:#939393;
   font-weight:500;
   background-color:#fffbf8;
   -webkit-box-shadow:
       0 -2px 2px 0 rgba(199, 199, 199, 0.55),
       0 1px 1px 0 #fff,
       0 2px 2px 1px #fafafa,
       0 2px 4px 0 #b2b2b2 inset,
       0 -1px 1px 0 #f2f2f2 inset,
       0 15px 15px 0 rgba(41, 41, 41, 0.09) inset;
   -moz-box-shadow: 
       0 -2px 2px 0 rgba(199, 199, 199, 0.55),
       0 1px 1px 0 #fff,
       0 2px 2px 1px #fafafa,
       0 2px 4px 0 #b2b2b2 inset,
       0 -1px 1px 0 #f2f2f2 inset,
       0 15px 15px 0 rgba(41, 41, 41, 0.09) inset;
   box-shadow:
       0 -2px 2px 0 rgba(199, 199, 199, 0.55),
       0 1px 1px 0 #fff,
       0 2px 2px 1px #fafafa,
       0 2px 4px 0 #b2b2b2 inset,
       0 -1px 1px 0 #f2f2f2 inset,
       0 15px 15px 0 rgba(41, 41, 41, 0.09) inset;
}
.btn-input-search[type="submit"]{
   width:35px;
   height:30px;
 
   background-repeat: no-repeat;
   background-position: 10px 5px;
   background-color:transparent;
   -webkit-background-size:20px 20px;
   background-size:20px 20px;
   border:none;
   cursor:pointer;
}
.input-search[type="search"]:focus{
   outline:0;
}
.signins-container{
 margin-top: 5% !important;
}

.signupup-text{
 text-align: center;
}
.signup-gen{
 
 justify-content: center;
 padding-top: 5%;
 
}
#booking-form-container{
 margin-left: 25%;
 border-radius: .25rem;
 box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
 box-sizing: border-box;
 color: white;
 padding: 10px;
 background-color: white;
}
.filld-fo{
 color: black;
 text-align: center;
}
.login-btn-login{
 background-color: #030334 !important;
 display: block !important;
}
.carticon-up{
 color: black;
}
.badge {
 color: black !important;
}
.myaccount-list{
 display: flex;
 z-index: 100;
 position: absolute;
 gap: 30px;
 width: 400px;
 left: 3.5%;
 margin-top: 1%;
 list-style-type: none;
 font-weight: bold;
 cursor: pointer;
}
.humanicon{
 margin-top: -5px;
}
.linkstyle{
 text-decoration: none;
 color: #030334;
}
.linkstyle :hover{
   color: black;
}
.cartpush-div{
 margin-top: 4%;
 
}


.cards_item-item {
 display: flex;
 padding: 1rem;
 margin-top: 0%;
 margin-left: 4%;
 

}
.filter_catControl_mobile{
 position: absolute;
 width: 200px !important;
 z-index: 50;
 padding: 10px;
 background: #FFF;
 border: 1px solid #CDD3E2;
 box-shadow: 0px 0px 1px #CCC;
 border-radius: 4px;
 cursor: default;

 
}
.hiDe_Cat_list{
position: absolute;

list-style-type: none;
width: 200px !important;
margin-left: -5%;
margin-top: 10px;

background: #FFF;
border: 1px solid #CDD3E2;
box-shadow: 0px 0px 1px #CCC;
border-radius: 4px;
cursor: pointer;
display: none;

}
.filter_catControl_mobile:hover .hiDe_Cat_list{
 display: block ;
}
.catogory_ListCheck{
 font-weight: bold;
 padding: 5px;
}
.catogory_ListCheck:hover{
 color: darkorange;
}



.trycart{
 margin-top: 20% !important;
 /* border: 1px solid rgba(0, 0, 0, 0.1);
 border-radius: .25rem;
 box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
 box-sizing: border-box;
 color: white; */
}
.sectioncart{
 display: flex !important;
}
/* .cccover{
 border-radius: .25rem;
 box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
 box-sizing: border-box;
 color: white;
}  */

.text-navy {
color: #030334;
}
.cart-product-imitation {
text-align: center;
padding-top: 30px;
height: 180px;
width: 150px;
background-color: #f8f8f9;
padding: 5px;
}
.product-imitation.xl {
padding: 120px 0;
}
.product-desc {
padding: 20px;
position: relative;
margin-left: 2% !important;
width: 100% !important;
}
.ecommerce .tag-list {
padding: 0;
}
.ecommerce .fa-star {
color: #d1dade;
}
.ecommerce .fa-star.active {
color: #f8ac59;
}
.ecommerce .note-editor {
border: 1px solid #e7eaec;
}
table.shoping-cart-table {
margin-bottom: 0;
}
table.shoping-cart-table tr td {
border: none;
text-align: right;
}
/* table.shoping-cart-table tr td.desc,
table.shoping-cart-table tr td:first-child {
text-align: left;
} */
table.shoping-cart-table tr td:last-child {
width: 80px;
}
.ibox {
clear: both;
margin-bottom: 25px;
margin-top: 0;

padding: 0;
border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: .25rem;
box-shadow: 0 10px 10px -14px rgba(0, 0, 0, 0.25) ;
box-sizing: border-box;
color: white;
}
.ibox.collapsed .ibox-content {
display: none;
}
.ibox:after,
.ibox:before {
display: table;
}
.ibox-title {
-moz-border-bottom-colors: none;
-moz-border-left-colors: none;
-moz-border-right-colors: none;
-moz-border-top-colors: none;
background-color:#008ABF;
border-color:black;
border-image: none;
border-style: solid solid none;
border-width: 3px 0 0;
color: inherit;
margin-bottom: 0;
padding: 14px 15px 7px;
min-height: 48px;
}
.ibox-content {
background-color: #ffffff;
color: inherit;
padding: 15px 20px 20px 20px;
border-color: #e7eaec;
border-image: none;
border-style: solid solid none;
border-width: 1px 0;
display: flex;
flex-wrap: wrap;
justify-content: space-around;


}
.displa-AlertCart{
  position: absolute;
  width: 30%;
  z-index: 200;
  margin: 0 auto !important;
  justify-content: center !important;
  text-align: center !important;
}
.ibox-conten-check{
  background-color: #ffffff;
color: inherit;
padding: 15px 20px 20px 20px;
border-color: #e7eaec;
border-image: none;
border-style: solid solid none;
border-width: 1px 0;

}
.imgMOdal{
  width: 50px;
  
}
.imtDiv{
  /* background-color: #008ABF; */
  justify-items: center;
  text-align: center;
}
.itName{
  font-weight: bold;
  color: #062774;
}
.imgMOdalDiv{
 /* display: flex;
 flex-wrap: wrap; */
}



.ibox-footer {
color: inherit;
border-top: 1px solid #e7eaec;
font-size: 90%;
background: #ffffff;
padding: 10px 15px;
}
.container-sp{
  padding: 2%; 
 padding-left: 12%;
 padding-right: 12%; 
 /* margin-top: -5% !important;  */
}
.aCartMpro{
  background-color: #16222A;
}

.proDisplayforcart{
  color: green;
}
.inner-cartstyle{

}
.liIcon_mobile_div{
 display: flex;
 gap: 30px;
}
.font-bold{
 color: black;
}
.totalspan{
 color: black;
}
.btnlogin{
 height: 50px;
 color: white;
 border-radius: 10px;
}
.pwarning{
 font-size: 12px;
 text-align: center;
 margin-top: 2%;
}
.pwarning2{
 text-align: center;
 font-size: 15px;
}
.container-profilemain{

 justify-content: center !important;
 padding-top: 2%;
}
.container-profilemain {
 
   padding: 40px; 
 
}
.cardimage-profile{
 border: 1px solid rgba(0, 0, 0, 0.1);
 border-radius: .25rem;
 box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
 box-sizing: border-box;
 color: white;
}
.cardimage-profile1{
 border: 1px solid rgba(0, 0, 0, 0.1);
 border-radius: .25rem;
 box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
 box-sizing: border-box;
 margin-left: 38px;
 margin-right: 38px;

}
.cardimage-profile2{
 border: 1px solid rgba(0, 0, 0, 0.1);
 border-radius: .25rem;
 box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
 box-sizing: border-box;
 }
 .show_hideBig{
   display: flex;
   gap: 30px;
   margin-left: -1% !important;
 }
 
.ordersdisplay{
display: flex;
gap: 3%;
}
.ordersdisplay-img{
 width: 100px;
 height: 100px;
}
.contactnum-span{
 color: #05056d;
 text-decoration: underline;
}
.list-group-flush{
 list-style-type: none;
 font-weight: bold;
 cursor: pointer;
 
}
.list-group-flush li{
 padding: 10px;
 cursor: pointer;
}
.wviewmore{
 text-align: center !important;
 cursor: pointer;
 /* margin-left: 70% !important; */
}
.wviewmore:hover{
 color: darkorange;
}

.btn-info-i{
 background-color: #030334 !important;
 color: white !important;
}
.mainbookinpage-all{
 background-color: #F1F1F2;
 position: relative;
 top: 0;
 bottom: 0;
 height: 100%;
}
.continuebtn-shopping:hover{
  color: darkorange !important;
}
.list-group-flush li:hover{
 background-color: #030334 !important;
 color: white;
}
.button-toop{
 margin-top: 2%;
}
.form-alerts{
 position: absolute;
 top: 90vh;
 background-color: #030334;
 border-radius: 10px;



}

.iconMark{

 color: #054B05;
}
.title-Cart-modal{
 text-align: center !important;
 color: #054B05;
}
.modalCart-Warn{
 display: flex;
 gap: 10%;
}
.text-ADDcart{
 display: block !important;
}
.me-4{
 width: 40px;
 height: 40px;
}
.btn-close{
 color: white !important;
 background-color: white !important;
 z-index: 5;
}
.showredirection button{
  margin-left: 20%;
  padding: 5px;
  border-radius: 10px;
  background-color: #81819A;

}
.trashChangeCol{
  color: rgb(209, 17, 17);
}
.showredirection1{
  font-size: 20px;
}
.showredirection2{
font-size: 20px !important;
}
.showredirection button:hover{
        background-color: darkorange;
        color: white;
}
.controlcounter{
 display: flex;
 margin-left: 300px;
 
}
.price-back{
 /* background-color: rgba(0, 0, 0, 0.288); */
 color: black;

  padding: 7px; 
 justify-content: center;
 /* width: 20%; */
}
.tractminus{
   background-color: #030334;
   margin-right: 2%;
   box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ;
   box-sizing: border-box;
   border: 1px solid rgba(0, 0, 0, 0.1);
   border-radius: .25rem;
   color: white;
   
}
.trackplus {
 background-color: #030334;
 margin-left: 2%;
 box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
 box-sizing: border-box;
 border: 1px solid rgba(0, 0, 0, 0.1);
 border-radius: .25rem;
 color: white;

}
.tractminus:hover{
 background-color: #2BBCF1;
 z-index: 5;
}
.trackplus:hover{
 background-color: #2BBCF1;
}
.desc{
  width: 100% ;
  
  
}
.yenProduct{
  flex-wrap: wrap;
  /* width: 20%; */
}

.table-responsive{
 justify-content: center;
 text-align: left;
}
.setFOrm-modal{
  padding: 10px !important;
}
.addRESs-input{
  width: 100%;
  height: 40px;
}

.incrementnum{
 font-weight: bold;
 margin-top: 1%;
 color: black;
 }
.boldtesting{
 font-weight: bold;
 color: #1F2D68;
}
.spanLINE-cart{
border: #030334 solid 2px ;
  height: 1px;
  width: 10% !important;
  border-radius: 5px;
}
.descriptioncover{

 /* width: 100%; */
 color: rgb(131, 125, 125);
 /* display: block; */
 /* margin-top: -5%; */
 flex-wrap: wrap;

}
.imagedesign-manager{
 width: 250px;
 height: 200px;
}
.allsearch-drop{
 background-color: white; 
 border-color: rgba(0, 0, 0, 0.15);
 box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
 color: rgba(0, 0, 0, 0.65);

 position: absolute; 
 width: 44.5rem; 
 z-index: 5;
}
.picSearch-control{
 width: 40px;
 
}
.ulcontrol-search li{
 list-style-type: none;
 cursor: pointer;
 padding: 5px;
}
.ulcontrol-search li:hover{
 color: darkorange;
}
.linktodisplay-search{
 text-decoration: none !important;
 color: black;
 
}
.imageContolCorol{
width: 100%;
 height: 800px !important;
}
.second-ecomercenav{

margin-top: -4% !important;
 width: 100% !important;
 z-index: 5;

 justify-content: center !important;
 background-color: #030334 !important;
}
.pnamecenter-side{

 font-weight: bold;
}
.pspan-sideprice{
 font-size: large;
}
.search-bootstrap-input{
 text-align: center !important;
 left: 0 !important;
}

.forms-inputearch{
 width: 100% !important;
}
.addedsearch-boot{
 background-color: #030334;
 color: white;
}
.footerchange-shopping{
 margin-top: 10% !important;
}
.categoryText-Check{
 background-color: #090947;
 position: absolute;
 width: 90%;
 color: white;
 text-align: center;
 padding: 5px;

 
}




/* .errortec, .back-homeQr{
  margin: 0;
  padding: 0;
  text-decoration: none;
} */

.sectionSEarch{
  padding: 2rem 2rem;
}

.sectionSEarch .errortec{
  font-size: 40px;
  color: #1C6ED0;
  text-shadow: 
    1px 1px 1px #00593E,    
    2px 2px 1px #00593E,
    3px 3px 1px #00593E,
    4px 4px 1px #00593E,
    /* 5px 5px 1px #00593E,
    6px 6px 1px #00593E,
    7px 7px 1px #00593E,
    8px 8px 1px #00593E, */
    /* 0px 0px 2px rgba(0,0,0, 0.2); */
}

.pageTIck{
  margin: 3rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
  
}

.back-homeQr{
  display: inline-block;
  border: 2px solid #222;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 15px 15px -11px rgba(0,0,0, 0.4);
  background: #222;
  border-radius: 6px;
}
.back-homeQr:hover{
  background: #222;
  color: #ddd;
}



@media(max-width:1700px){
 .container-sp{
   
 }
}
@media(max-width:1400px){
 .container-sp{
  
 }
}
.text-muted:hover{
 color: darkorange;
 cursor: pointer;
}




/* CSS */
.button-24 {
 background: #FF4742;
 border: 1px solid #FF4742;
 border-radius: 6px;
 box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
 box-sizing: border-box;
 color: #FFFFFF;
 cursor: pointer;
 display: inline-block;
 font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
 font-size: 16px;
 font-weight: 800;
 line-height: 16px;
 min-height: 40px;
 outline: 0;
 padding: 12px 14px;
 text-align: center;
 text-rendering: geometricprecision;
 text-transform: none;
 user-select: none;
 -webkit-user-select: none;
 touch-action: manipulation;
 vertical-align: middle;
}

.button-24:hover,
.button-24:active {
 background-color: initial;
 background-position: 0 0;
 color: #FF4742;
}

.button-24:active {
 opacity: .5;
}
.toast-messages{
 background-color: rgba(0, 128, 0, 0.815) !important;
 color: white !important;
 border: #030334 solid 1px;
}
.redNote-show{
 color: red;
}
.total-amount-sid{
 font-weight: bold;
}
.place-order-warnContainer{
 display: flex;
}
.signupcontainer{
 padding-top: 5%;
}
.control-emptyContainer{
 padding-top: 5% ;
 
}
.logout-bookingside{
 color: darkorange;
}
.logout-bookingside:hover{
 color: red;
 cursor: pointer;
}

/* loader  */
.lds-spinner {
 color: official;
 display: inline-block;
 position: relative;
 width: 80px;
 height: 80px;
}
.lds-spinner div {
 transform-origin: 40px 40px;
 animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
 content: " ";
 display: block;
 position: absolute;
 top: 3px;
 left: 37px;
 width: 6px;
 height: 18px;
 border-radius: 20%;
 background: #fff;
}
.lds-spinner div:nth-child(1) {
 transform: rotate(0deg);
 animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
 transform: rotate(30deg);
 animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
 transform: rotate(60deg);
 animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
 transform: rotate(90deg);
 animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
 transform: rotate(120deg);
 animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
 transform: rotate(150deg);
 animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
 transform: rotate(180deg);
 animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
 transform: rotate(210deg);
 animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
 transform: rotate(240deg);
 animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
 transform: rotate(270deg);
 animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
 transform: rotate(300deg);
 animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
 transform: rotate(330deg);
 animation-delay: 0s;
}
@keyframes lds-spinner {
 0% {
   opacity: 1;
 }
 100% {
   opacity: 0;
 }
}
.paystacbtn{
 background-color:#054B05 !important;
 font-size: 25px;
 z-index: 200 !important;
 /* position: absolute; */
}

.myacountlist{
 color: white !important;
 text-decoration: none;
}
.myacountlist:hover{
   color: darkorange !important;
}
.checking-product-details{
 cursor: pointer;
 margin-top: 5%;
}
.control-backgroundnav{
 background-color: #030334 !important;
 color: white !important;
 margin-top: -4% !important;
}
.colapse-nav-profile{
 color: white !important;
 margin-left: 80px !important;
 cursor: pointer;
}
.colapse-nav-profiles{
 color: white !important;
 margin-left: 80px !important;
 cursor: text;

}
.colapse-nav-profiless{
 color: white !important;
 margin-left: 80px !important;
 

}

.navcontrol-push{
 margin-left: 56% !important;
}
#navcontrol-pushId{
 margin-left: 54% !important;
}
.colapse-nav-profileNav{
 color: white !important;
 cursor: pointer;
 
 
 
}
.back-dropIcon{
 background-color:rgba(255, 255, 255, 0.888);
 color: white !important;
 
}
.proHide-link{
 text-decoration: none;
}
.logout-take{
 color: red;
}
.ebe-containersup{
 width: 100%;
 height: 50px;
 color: white;
 background-color: #030334;
 margin-top: -3% !important;
 z-index: 100 !important;
 
}
.ebe-containersup-ec{
  padding: 10px;
  font-size: large;
}
.meControl{
 position: absolute;
 margin-left: 50%;
 margin-top:-15% ;

}


/* CSS */
.button-57 {
 position: relative;
 overflow: hidden;
 border: 1px solid #18181a;
 color: #18181a;
  display: inline-block;
 font-size: 15px;
 line-height: 0px;
  padding: 18px 18px 17px; 
 text-decoration: none;
 cursor: pointer;
 background: #fff;
 user-select: none;
 -webkit-user-select: none;
  touch-action: manipulation;  
 width: 100%;
 
 top: 0;
 bottom: 0;
}

.button-57 span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);  
 
}
.desc h4{
  color: #030334;
}

.button-57 span:last-child {
 color: white;
 display: block;
 position: absolute;
 bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1); 

 opacity: 0;
 top: 50%;
 left: 50%;
 transform: translateY(225%) translateX(-50%);
 height: 10px;
 line-height: 10px;
}

.button-57:after {
content: "";
 position: absolute;
 bottom: -50%;
 left: 0;
 width: 100%;
 height: 100%; 
 background-color:#3B3B61;
 transform-origin: bottom center;
 transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1); 
  transform: skewY(9.3deg) scaleY(0);   
 
}

.button-57:hover:after {
 transform-origin: bottom center; 
   transform: skewY(0) scaleY(5); 
}

.button-57:hover span:last-child {
  transform: translateX(-50%) translateY(-100%); 
 opacity: 1;
 transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}

.bn13 {
 display: inline-block;
 padding: 0.65em 1.6em;
 border: 3px solid black;
 margin: 0 0.3em 0.3em 0;
 border-radius: 0.12em;
 box-sizing: border-box;
 text-decoration: none;
 font-family: "Roboto", sans-serif;
 font-weight: 300;
 color: #ffffff;
 text-align: center;
 transition: all 0.2s;
 animation: bn13bouncy 5s infinite linear;
 position: relative;
 background-color: #030334 !important;
 text-decoration: none;
 border-radius: 5px !important;
 z-index: 20;
}

.bn13:hover {
 background-color: #030334 !important;
 text-decoration: none;
 color: darkorange;
}
.remove-linkline:hover{
 text-decoration: none;
}

@keyframes bn13bouncy {
 0% {
   top: 0em;
 }
 40% {
   top: 0em;
 }
 43% {
   top: -0.9em;
 }
 46% {
   top: 0em;
 }
 48% {
   top: -0.4em;
 }
 50% {
   top: 0em;
 }
 100% {
   top: 0em;
 }
}
.centerprofile-pend{
 justify-content: center;
 text-align: center;
}

/* new alert start */
.alert{
 width:100%;
 margin:20px auto;
 background-color: rgb(9, 77, 9);
 padding:5px;
 position:relative;
 border-radius:5px;
 box-shadow:0 0 15px 5px #ccc;
 color: white;
}
.close{
 position:absolute;
 width:30px;
 height:30px;
 opacity:0.5;
 border-width:1px;
 border-style:solid;
 border-radius:50%;
 right:15px;
 
 text-align:center;
 font-size:1.6em;
 cursor:pointer;
}
.closeHomeGo{
 position:absolute;
 right:80px;
  text-align:center;
 font-size:1.6em;
 cursor:pointer;
 margin-top: -5px;
 color: white;
}
.closeHomeGo:hover{
 color: darkorange;
}

/* new allert end */


.tbview-head-history{
 background-color: #030334;
 color: white;
 z-index: 5;
}

.formWhite-contain {

 width: 45rem;
 background: var(--color-brand);
 border-radius: var(--rad);
 justify-content: center;
 margin-left: 29.5%;
 border: 3px solid #030334 ;
 background-color: white;
 border-color: rgba(0, 0, 0, 0.15);
 box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
 border-radius: 50px;


}
.linkRemoveBook{
 text-decoration: none;
 margin-top: -2%;
}

.form-testers-white, .btn-testinWhite {
  height: var(--height); 
 font-family: var(--font-fam);
 border: 0;
 color: var(--color-dark);
 font-size: 1.8rem;
}
.form-testers-white {
 outline: 0; 
 width: 100%;
 background: var(--color-light);
 padding: 0 1.6rem;
 border-radius: var(--rad);
 appearance: none; 
 transition: all var(--dur) var(--bez);
 transition-property: width, border-radius;
 z-index: 1;
 position: relative;
 font-size: 18px;
  height: 45px; 
 
}
.btn-testinWhite{
 display: none; 
 position: absolute;
 top: 0;
 right: 0;
 width: var(--btn-width);
 font-weight: bold;
 background: var(--color-brand);
 border-radius: 0 var(--rad) var(--rad) 0;
}

.labelContain {
 position: absolute;
 clip: rect(1px, 1px, 1px, 1px);
 padding: 0;
 border: 0;
 height: 1px;
 width: 1px;
 overflow: hidden;
}
.changePassw-background{
 
 padding: 10px;
 border-radius: 10px;
 cursor: default;

 justify-content: center !important;
 text-align: center !important;
}
.changePassw-background:hover{
  color: darkorange;
}

#id-back-login{
 background-color: #030334;
 color: white;
 padding: 2px;
}
#id-back-login :hover{
 
 color: white;
 
}
.searchIn-sec{
 /* position: absolute; */
 margin-left: -7%;
 margin-top: 0%;
 cursor: pointer;
}
.dashProfile{
 background-color: #090947fb;
 color: white;
 cursor: text !important;
}
.history-Contain{
 padding-left:10% ;
 padding-right:10% ;
 margin-top: 2%;
}

.searchHistory-hiscon{
 display: flex;
 justify-content: space-between;
}

.main-search-input {
 background: #030334;
 padding: 0 120px 0 0;
 border-radius: 1px;
 
 box-shadow: 0px 0px 0px 6px rgba(255,255,255,0.3);
}

.fl-wrap {
 float: left;
 width: 100%;
 position: relative;
}

.main-search-input:before {
 content: '';
 /* position: absolute; */
 bottom: -40px;
 width: 50px;
 height: 1px;
 background: rgba(255,255,255,0.41);

}

.main-search-input-item {
 float: left;
 width: 100%;
 box-sizing: border-box;
 border-right: 1px solid #eee;
 height: 50px;
 position: relative;
}

.main-search-input-item input:first-child {
 border-radius: 100%;
}

.main-search-input-item input {
 float: left;
 border: none;
 width: 150%;
 height: 50px;
 padding-left: 20px;
}

.main-search-button{

   background: #090947;
}


.main-search-button {
 position: absolute;
 right: 0px;
 height: 50px;

 color: #fff;
 top: 0;
 border: none;
 border-top-right-radius: 0px;
 border-bottom-right-radius: 0px;
 cursor: pointer;
}

.main-search-input-wrap {
 /* max-width: 500px; */
 /* margin: 20px auto; */
 /* position: relative; */
 float: left;
}

:focus {
 outline: 0;
}
.linkHistory-remove{
 color: black;
 text-decoration: none;
}
.linkHistory-remove:hover{

 text-decoration: none;
}
.history-footerContainer{
 margin-top: 5% !important;
}
.picModal-add{
 width: 70px;
 height: 70px;
}

.button-60 {
 align-items: center;
 appearance: none;
 background-color: #fff;
 border: 1px solid #dbdbdb;
 border-radius: .375em;
 box-shadow: none;
 box-sizing: border-box;
 color: #363636;
 cursor: pointer;
 display: inline-flex;
 font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
 font-size: 1rem;
 height: 2.5em;
 justify-content: center;
 line-height: 1.5;
 padding: calc(.5em - 1px) 1em;
 position: relative;
 text-align: center;
 user-select: none;
 -webkit-user-select: none;
 touch-action: manipulation;
 vertical-align: top;
 white-space: nowrap;
}

.button-60:active {
 border-color: #4a4a4a;
 outline: 0;
}

.button-60:focus {
 border-color: #485fc7;
 outline: 0;
}

.button-60:hover {
 border-color: #b5b5b5;
}

.button-60:focus:not(:active) {
 box-shadow: rgba(72, 95, 199, .25) 0 0 0 .125em;
}
.image-controlTin{
 width: 100px !important;
 height: 100px !important;
}
.productProfile-flex{
 display: flex !important;
}
.control-serchBox{
 background-color: rgb(255, 255, 255);
 position: relative;
}
.tr-container-product{
 display: flex;
 gap: 2%;
}



.movie--isloading {
 height: 252px;
 border-radius: 4px;
 overflow: hidden;
 margin-bottom: 15px;
}

.movie--isloading .loading-image {
 height: 190px;
 background-image: -webkit-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
 background-image: -o-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
 background-image: linear-gradient(90deg, #ececec 0px, #f4f4f4 40px, #ececec 80px);
 background-size: 250px;
 -webkit-animation: shine-loading-image 2s infinite ease-out;
         animation: shine-loading-image 2s infinite ease-out;
}

.movie--isloading .loading-content {
 background: #f7f7f7;
 padding: 15px;
 display: -webkit-box;
 display: -ms-flexbox;
 display: flex;
 -webkit-box-align: center;
     -ms-flex-align: center;
         align-items: center;
 -webkit-box-pack: center;
     -ms-flex-pack: center;
         justify-content: center;
}

.movie--isloading .loading-content .loading-text-container {
 -ms-flex-preferred-size: 100%;
     flex-basis: 100%;
}

.movie--isloading .loading-content .loading-main-text {
 height: 10px;
 width: 65%;
 margin-bottom: 10px;
 background: #ececec;
 background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
 background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
 background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
 background-size: 250px;
 border-radius: 10px;
 -webkit-animation: shine-loading-container-items 2s infinite ease-out;
         animation: shine-loading-container-items 2s infinite ease-out;
}

.movie--isloading .loading-content .loading-sub-text {
 height: 10px;
 width: 50%;
 background: #ececec;
 background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
 background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
 background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
 background-size: 250px;
 border-radius: 10px;
 -webkit-animation: shine-loading-container-items 2s infinite ease-out;
         animation: shine-loading-container-items 2s infinite ease-out;
}

.movie--isloading .loading-content .loading-btn {
 width: 60px;
 height: 25px;
 background: #ececec;
 background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
 background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
 background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
 background-size: 250px;
 border-radius: 3px;
 -webkit-animation: shine-loading-container-items 2s infinite ease-out;
         animation: shine-loading-container-items 2s infinite ease-out;
}
.iconPersonHide{
 position: absolute;
 display: none;
}


@-webkit-keyframes shine-loading-image {
 0% {
   background-position: -32px;
 }
 40%, 100% {
   background-position: 208px;
 }
}


@keyframes shine-loading-image {
 0% {
   background-position: -32px;
 }
 40%, 100% {
   background-position: 208px;
 }
}

@-webkit-keyframes shine-loading-container-items {
 0% {
   background-position: -100px;
 }
 40%, 100% {
   background-position: 140px;
 }
}

@keyframes shine-loading-container-items {
 0% {
   background-position: -100px;
 }
 40%, 100% {
   background-position: 140px;
 }
}
.containerrrr{
 display: flex;
 gap: 5%;
}

.cardSkeletonDis{
 display: grid;
 grid-template-columns: auto auto auto ;

}
#cardSkelotoIdT{
 
 background: #ececec;
 background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
 background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
 background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
 background-size: 250px;
 border-radius: 10px;
 -webkit-animation: shine-loading-container-items 2s infinite ease-out;
         animation: shine-loading-container-items 2s infinite ease-out;
}

.proBold{
 font-weight: bold;
}
.greenPros{
 color: green;
}

.loader-loads {
 width: 50px;
 height: 50px;
 border: 5px dotted #0D0D4A;
 border-radius: 50%;
 display: inline-block;
 position: relative;
 box-sizing: border-box;
 animation: rotation 2s linear infinite;
}
.deliverySideNote{
  display: flex;
  gap: 5px;  
}

.loader-load {
 width: 20px;
 height: 20px;
 border: 5px dotted #FFF;
 border-radius: 50%;
 display: inline-block;
 position: relative;
 box-sizing: border-box;
 animation: rotation 2s linear infinite;

}


#adminControlTotal{
 width: 25px;
 height: 25px;
 border: 5px dotted darkorange;
 border-radius: 50%;
 display: inline-block;
 position: relative;
 box-sizing: border-box;
 animation: rotation 2s linear infinite;
}

.coverIcode-side{
 border: 0.5px solid;
 padding: 2px;
}
.warn-standd{
 color: red;
}
.hoverBTN-profile:hover{
 background-color: #030334;
}

@keyframes rotation {
 0% {
   transform: rotate(0deg);
 }
 100% {
   transform: rotate(360deg);
 }
} 
.modal-historyHead{
 color: #030334;
}
.pic-ModalProduct{
 width: 60px;
 height: 60px;
}
.linkStrikeOut{
 text-decoration: none;
 color: #030334;
}
.linkStrikeOut:hover{
 text-decoration: none;
}
.controlFocus{
margin-top: 8px;
display: block;
z-index: 200 !important;
}
.controlFocus.focus{
 background-color: #030334 !important;
 color: white !important;
}
#mainbookinpage-allId{

 background-size: cover;
 
}

.skeleton-loader {
 width: 100%;
 height: 15px;
 display: block;
 background: linear-gradient(	  
     to right,
     rgba(255, 255, 255, 0),
     rgba(255, 255, 255, 0.5) 50%,
     rgba(255, 255, 255, 0) 80%
   ),
   lightgray;
 background-repeat: repeat-y;
 background-size: 50px 500px;
 background-position: 0 0;
 animation: shine 1s infinite;	  
}
@keyframes skeleton-loader {
 0% {
   background-position: -32px;
 }
 40%, 100% {
   background-position: 208px;
 }
}
#searchBoX{
 margin-top: 0 !important;
 margin-left: 30%;
 width: 400px;
}
.divContolFocus{

  width: 250px;
  display:block ;
  margin-top: 2%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  /* box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25) ; */
  box-sizing: border-box;
  margin-left: 5%;
  z-index: 100 !important;
  background-color: white;
}
.description-Text{
 font-size: 16px;
 /* display: block; */
 color: #030334;
 margin-top: 1%;
 flex-wrap: wrap;
 /* width: 20%; */

}
.AlertRed-quantity{
 text-align: center !important;
 color: red;
 padding: 5px;
}
.forgetPassword-btn{
 cursor: pointer;
}
.forgetPassword-btn:hover{
 color: darkorange;
}
.loader {
 width: 48px;
 height: 48px;
 border-radius: 50%;
 position: relative;
 animation: rotate 1s linear infinite;
 
}

.liIcon_mobile{
 display: none;
}
.loader::before {
 content: "";
 box-sizing: border-box;
 position: absolute;
 inset: 0px;
 border-radius: 50%;
 border: 5px solid #FFF;
 animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
 100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
   0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
   25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
   50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
   75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
   100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
.test4reset{
 text-align: center;
}
.navbar-collapse{
 /* margin-left: -20% !important; */
}
#naVShift{
 margin-left: -15% !important ;
 background-color: black;
 
}
.back-dropIcon{
 display: none;
}
.link_LogBig{
 background-color: #030334;
 color: white;
 padding: 10px;
 margin-top: -8%;
}
#hideregiste:hover{
 color: darkorange;
}

@media(max-width: 1800px){
 #cardsId{
  
   grid-template-columns: auto auto auto  ;
}
.card_image img {
 width: 350px;
 height: 300px;
 object-fit: cover;
 cursor: pointer;
 margin-left: 0% !important;
}
.card_image_main{
 width: 400px !important;
 height: 300px !important;
 object-fit: cover;
 cursor: pointer;
 margin-left: 0% !important;
}
.card-eco{
 width: 400px;
 margin-left: 25%;
}


 .cardSkeletonDis{
   display: grid !important;
   grid-template-columns: auto auto auto !important  ;

 }
 
}


@media(max-width: 1650px){
 #cardsId{
  
   grid-template-columns: auto auto auto  ;
}
.card_image img {
 width: 350px;
 height: 300px;
 object-fit: cover;
 cursor: pointer;
 margin-left: 0% !important;
}
.card_image_main{
 width: 400px !important;
 height: 300px !important;
 object-fit: cover;
 cursor: pointer;
 margin-left: 0% !important;
}
.card-eco{
 width: 400px;
 margin-left: 12%;
}


 .cardSkeletonDis{
   display: grid !important;
   grid-template-columns: auto auto auto !important  ;

 }
 
}


@media(max-width: 1400px){
 #cardsId{
  
   grid-template-columns: auto auto auto  ;
   margin-top: 11% !important;
   

 }
 .card_image img {
   width: 350px;
   height: 300px;
   object-fit: cover;
   cursor: pointer;
   margin-left: 0% !important;
 }
 .card_image_main{
   width: 400px !important;
   height: 300px !important;
   object-fit: cover;
   cursor: pointer;
   margin-left: 0% !important;
 }
 .card-eco{
   width: 350px;
   margin-left: -4%;
 }

 .navcontrol-push{
   margin-left: 58% !important;
 }
 
 #naVShift{
   margin-left: -30% !important ;
 }

 .control-backgroundnav{
  
   margin-top: -5% !important;
 }


 .button-16{
   margin-left: 40%;
 }
 .cardSkeletonDis{
   display: grid !important;
   grid-template-columns: auto auto auto   !important  ;

 }
 .main-eco-goods {
   max-width: 90%; 
    margin: 0 auto;
     margin-top: -10%;  
    justify-content: center !important;
    
   
   }
 
}
@media(max-width: 1200px){
 #cardsId{
  
   grid-template-columns: auto auto  ;
   

 }
 .card-eco{
   width: 350px;
   margin-left: 15%;
 }

 .cardSkeletonDis{
   display: grid !important;
   grid-template-columns: auto auto   !important  ;

 }
 
}


@media(max-width: 993px){
 .card-body{
   width: 100% !important;
 }

 .cardsId-Mimic{
   display: grid;
   margin-left: 0% !important;
 }
 .control-backgroundnav{
  
   margin-top: -8% !important;
 }
 .back-dropIcon{
   display: block;
 }
 #backDrop-Remove{
   display: none;
 }
 #control-backgroundnavssss{
   height: 50px !important;
 }

 #cardsId{
  
   grid-template-columns: auto auto  ;
   margin-top: 11% !important;
   }
 .card_image img {
   width: 300px;
   height: 300px;
   object-fit: cover;
   cursor: pointer;
   margin-left: 0% !important;
 }
 .card_image_main{
   width: 300px !important;
   height: 300px !important;
   object-fit: cover;
   cursor: pointer;
   margin-left: 0% !important;
 }
 .card-eco{
   width: 300px;
   margin-left: -20%;
 }
 
 
}
.hr-pro{
 width: 98%;
}



@media (max-width: 768px) {
 .menu-toggle {
   display: flex;
 }

 .nav-list {
   flex-direction: column;
   background-color: #333;
   position: absolute;
   top: 60px;
   left: 0;
   width: 100%;
   padding: 10px 0;
   box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
   transition: 0.3s;
   opacity: 0;
   visibility: hidden;
 }

 .nav-list.open {
   opacity: 1;
   visibility: visible;
 }

 .nav-item {
   text-align: center;
   padding: 10px 0;
 }

 .nav-item.dropdown {
   position: relative;
 }

 .dropdown-menu {
   position: absolute;
   top: 0;
   left: 100%;
   display: none;
   flex-direction: column;
   background-color: #333;
   border: 1px solid #777;
   padding: 10px 0;
   transition: 0.3s;
   opacity: 0;
   visibility: hidden;
 }

 .dropdown-menu.open {
   opacity: 1;
   visibility: visible;
 }
 .back-dropIcon{
   display: block;
 }
}




     

/* Ipad responsive */
@media(max-width: 993px){
 .howtobuy-cls{
  
   width: 88%;
   margin-left: 6%;
   margin-top: 8%;
}
.cardSkeletonDis{
 display: grid !important;
 grid-template-columns: auto auto   !important  ;

}
.formWhite-contain{
 margin-left: 15%;
 width: 70%;
 z-index: 100 !important;
 position: absolute;
}
.searchbox{
 width: 60%;
 margin-left: 20%; 
}
#hideregisterId{
 display: none;
}
.myaccount-list{
 margin-left: -7%;
 margin-top: 1%;
}
.cards-eco{
 margin-left: 5%;
}
.our-solution-text{
 margin-top: 14% !important;
}
.body3-main{
 display: grid !important;
}
.body3-text-div{
 margin-top: 10%;
}
.goodsdetails-container-flex{
 display: grid;
}
.image-details-con{
 width:700px ;
}
.second-downimg{
 width: 160px; 
}
.main-eco-goodsrelated{
 margin-top: 10%;
}
.container-sp{
 padding: 2%;
 padding-left: 12%;
 padding-right: 12%;
 margin-top: -5% !important;
 width: 100%;
}
#booking-form-container{
 width: 90% !important;
 margin-left: 5%;
 margin-top: 5%;
}
.booking-container{
 display: grid !important;
 margin-top: 5%;
}
.bookingform-main{
 width: 650px;
 
}
.cardimage-profile1{
 width: 100%;
 margin-left: 0;
}
.navcontrol-push{
 margin-left: -20% !important;
}
#navcontrol-pushId{
 margin-left: -20% !important;
}
#cardsId{
 display: grid;
 grid-template-columns: auto;
}
.cart-icon{
 
 left: 85%;

 font-size: 20px;
 color: black;
}
#cardsId{
    
 grid-template-columns: auto auto ;
 margin-top: 16% !important;
 

}
.allsearch-drop{
 width: 100%; 
}
.tr-container-product{
 display: grid;
 
}
.hr-pro{
 width: 94%;
}
.sidedetails-containerDetails{
 
 width: 700px;
  
  padding-top: -40px !important;

 
}
.second-displayEcom{
margin-top: 10%;
}
.cards_item-item{
 margin-left: 22%;
}
.cards_item-eco{
 margin-left: 22%;
}
.divContolFocus{
 margin-left: 23%;
 margin-top: 5%;
}
#naVShift{
 margin-left: 10% !important ;
}

.main-eco-goods{
 margin-top: -15%;
}
.liIcon_mobile{
 display: block;
 margin-left: 3%;
 margin-top: 1%;
}
.liIcon_mobile_div{
 display: grid;
 position: absolute;
 margin-top: 8%;
 background: #030334;
 color: white;
 padding: 10px;
 margin-left: 3%;

}
.show_hideBig{
 display: none;
}
.second-ecomercenav{
 margin-top: -7% !important;
}
.hideMyAco{
 display: none;
}
.nameDisplay_account{
 background-color: white !important;
 color: #030334 !important;
}
.yepPushSearch-ecom{
  margin-top: 8%;
}
.container-sp{
  margin-top: 2% !important;
}
.safeJpp{
  width: 100% !important;
}

}

/* mobile responsive */
@media(max-width:640px){
 .input-search{
   width: 80% !important;
 }
 .cardSkeletonDis{
   display: grid !important;
   grid-template-columns: auto  !important  ;

 }
 .myaccount-list{
   margin-left: -10%;
   margin-top: 3.3%;
 }
 .formWhite-contain{
   margin-left: 16%;
   width: 70%;
 }
 .cart-icon{
 
   left: 85%;
   margin-top: 3%;
   font-size: 20px;
   color: black;
 }
 .bookingform-main{
   width: 350px;
   
 }
 .cartHideonm{
   display: none;
 }
 .howtobuy-cls{
  
   width: 88%;
   margin-left: 6%;
   margin-top: 25%;
    display: grid;
   
}
.searchbox{
 width: 70%;
 margin-left: 16%; 
}
.allsearch-drop{
 width: 100%; 
}
.our-solution-text{
 margin-top: 29% !important;
}
.body3-image-div img{
    width: 300px;
}
.body3-text-div{
 width: 350px;
 margin-top: 5%;
}
.body3-text-div{
 margin-top: 16%;
}
.image-details-con{
 width:350px ;
 justify-content: center !important;
 
}
.imageContolCorol{
 width: 350px !important;
 height: 350px !important;
 margin-top: 15%;
}
.secondimages-container{
 display: grid !important;
 grid-template-columns: auto auto;
 margin-top: 2%;
 gap: 10px;
 justify-content: center;
}
.sidedetails-container{
 margin-left: 6%;
}
.main-eco-goodsrelated{
 margin-top: 18%;
}
.trending-head{
 text-align: center;
}
.ibox{
 margin-top: 13%;
 width: 120%;
 margin-left: -10%;
}
.item-itemRemove{
 display: none;
}
.cart-product-imitation{
 display: grid !important;
 float: left !important;

}
#booking-form-container{
 width: 90% !important;
 margin-left: 5%;
 margin-top: 10%;
}

.ordersdisplay{
 display: grid;
 gap: 3%;
 }
 .navcontrol-push{
   margin-left: -50% !important;
 }
 #navcontrol-pushId{
   margin-left: -50% !important;
 }
 .container-sp{
  margin-top: -7% !important;
 }
 .details-container{
  margin-top: 10%;
   
}
 
 .changePassw-background{
   margin-top: 2% !important;
   background-color: none !important;
 }
 .searchIn-sec{
  margin-top: -12%;
  margin-left: 2%;
 }
 .controlTable-his{
   display: block;
   max-width: -moz-fit-content;
   max-width: fit-content;
   margin: 0 auto;
   overflow-x: auto;
   white-space: nowrap;
 }
 .form-testers-white{
   height: 25px;
 }
 .form-testers-white[placeholder]{
   top: 10px;
   margin-left: 10px;
 }
 #cardsId{
    
   grid-template-columns: auto auto;
   margin-top: 30% !important;
   
 
 }
 .controlcounter{
   display: flex;
   margin-left: 60% !important;
   gap: 5%;
   
 }
 .hr-pro{
   width: 90%;
   display: none;
 }
 .controlFocus{
  
   font-size: 12px;
 }
 .divContolFocus{
   display: grid;
   width: 145px;
   display: block;
   margin-left: 0;
 }
 .card_image img {
   width: 150px;
   height: 120px;
   object-fit: cover;
   padding: 3px;
  
 }
 .card_price {
   position: absolute;
   bottom: 1px;
   right: 20px !important;
   display: flex;
   justify-content: center;
   align-items: center;
  
   border-radius: 0.25rem;
   background-color: #030334;
   font-size: 18px;
   font-weight: 700;
   padding: 5px;
   color: white;
 }
 .cards_item-eco{
   padding: 7px;
   margin-top: 28% !important;
   margin-left: 0%;
  
   
 }
 #hideMobilepro-ske{
   display: none !important;
 }
 .boldtesting{
   font-weight: bold;
   margin-top: -20% !important;
   padding: 5px;

 }
 .description-Text{
   font-size: 16px;
  
   /* display: block; */
   /* width: 50%; */
   padding:5px ;
 }
 .cart-product-imitation {
  
   padding-top: 30px;
   height: 180px;
   width: 150px;
   background-color: #f8f8f9;
   margin-left: 20%;
   }
   .ordersdisplay{
     display: flex;
     gap: 3%;
     width: 285px;
     }
     .container-profilemain {
 
       padding: 40px; 
        width: 100%;
   }
   .button-16{
     margin-left: 50%;
   }
 
 .second-displayEcom{
 margin-top: 15%;
 } 
 .sidedetails-containerDetails{
 
   width: 350px;
    
    padding-top: -40px !important;
 } 
 .add2cart-mobile{
   /* display: none; */
 }
 .cards_item-item{
   padding: 7px;
   margin-top: 18% !important;
   margin-left: 0%;
 }
 .addRemoveText{
   display: none;
 }
 #naVShift{
   margin-left: 20% !important ;
 }
 .main-eco-goods{
   margin-top: -26%;
 }
 .second-ecomercenav{

   margin-top: -15% !important;
   
  }
 .control-backgroundnav{
  
   margin-top: -15% !important;
 }
 .second-downimg{
   width: 100px;
   height: 100px; 
 }
 .hoverStyleImage{
   justify-content: center !important;
 }
 .back-dropIcon{
   display: block;
 }
 .iconPersonHide{
   position: absolute;
   display: block;
   margin-top: -2%;
 }
 .nameDisplay_account{
   display: none;
   margin-left: 5% !important;
   margin-top: -5%;
   position: absolute;
 }

 
 #cardsId{
  
   grid-template-columns: auto ;
   margin-top: 11% !important;
   }
 .card_image img {
   width: 300px;
   height: 300px;
   object-fit: cover;
   cursor: pointer;
   margin-left: 0% !important;
 }
 .card_image_main{
   width: 300px !important;
   height: 300px !important;
   object-fit: cover;
   cursor: pointer;
   margin-left: 0% !important;
 }
 .card-eco{
   width: 300px;
   margin-left: 4%;
 }
 .filter_catControl_mobile{
   margin-top: 15%;
   width: 50px !important;
   padding: 3%;
 }
 #linkstyleProtude{
   z-index: 100 !important;
  position: absolute;
  left: 84%;
  margin-top: 3.5%;
 }
 .categoryText-Check{
   display: none;
 }
 .display_BigS{
   display: none;
 }
 .desc{
  /* width: 50% !important; */
  background-color: #008ABF !;
}
.desc h2{
  width: 100% !important;
  flex-wrap: wrap !important;
  }
  


 
 }

 