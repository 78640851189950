@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.parentDataPage {
    
    margin-top: 2% !important;
}

.parentDataHero {
    height: 60vh;
    background-color: black;
    width: 100%;
    background-image: url("../../images/BA-SS.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #0096D1;
    padding-top: 5%;
  
   }

.appCardLogo-div{

}
.appCardLogo{
    
}

.dataBlueIsComingParent{
    /* background-color: antiquewhite; */
    height: 50%;
}
.dataTextCEnter{
    
}
.callingCall{
   background-color: #0b0749d0; 
   padding: 10px;
   color: white;
   text-align: center;
   
}
.callingDIv{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 10%;
    padding-right: 10%;
    
}
.callingDIv div{
    width: 50%;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    /* background-color: aquamarine; */
    /* padding: 5px; */
}
.dataText-side{
    margin-top: 5%;
}
.line-textSide{
    border: solid 3px #0096D1;
    width: 150px !important;
    border-radius: 2px;
 

}
.side-imageS{
    width: 400px;
    margin-top: 12%;
}
.cal{
    font-weight: bold;
}
.textDATa-side{

}


@media(max-width: 993px){
    .side-imageS{
        width: 300px;
    }
    .callingDIv div{
       
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        /* background-color: aquamarine; */
    }
}

@media(max-width: 700px){
    .callingDIv{
        display: grid !important;
        
        padding-left: 5%;
        padding-right: 5%;
    }
    .callingDIv div{
        width: 100%;
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        /* background-color: aquamarine; */
    }
    .side-imageS{
        display: none;
    }
   .parentDataHero{
    display: none;
   }
   .parentDataPage {
    
    margin-top: 10% !important;
}
}
