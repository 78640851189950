@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.ktReserveback {
    font-family: "Poppins", sans-serif;
    margin-top: 3%;
   
}

.backsetorder2 {
   
    /* height: 50vh; */
    background-image: url('../../images/CHUKSGGGG.png');
    background-size: cover;
     display: flex;
     background-color:  #E5E5E5 !important;
     flex-direction: column; 
     align-items: center;
   /* justify-content: space-between; */
    flex-wrap: wrap; 
}
.aling-reModal{
    text-align: center;
    color: #0A074E;
}


.backset2 {
    background-color: #e5e5e531;
    border-bottom-left-radius: 20px ;
    border-bottom-right-radius: 20px ;
  
    width: 100%;
   
    /* flex-direction: column;
    /* align-items: center;
    justify-content: center;
    flex-wrap: wrap; */
    /* gap: 40px; */
}
.ajustNmae{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.cenTERsimtype{
    text-align: center;
    justify-content: center;
}

.container2 {
    background-color: #FFFFFF;
    padding: 50px;
    width: 50%;
    margin-left: 10%;
    /* margin-top: 1%; */
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.ajustNmaellabel{
    color: rgba(53, 50, 50, 0.651);
}
.container-myForm{
    padding-left: 15%;
}

.heroImage {
    background-image: url('../../images/newbackimg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    background-color: #0A074E;
    height: 80%;
    width: 30%;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset -30px 0px 30px rgba(0, 0, 0, 0.5),
        inset 30px 0px 30px rgba(0, 0, 0, 0.5);
}
.reserVHead2 {
    font-size: 3em;
    font-weight: 700;
}

.reserVtxt2 {
    width: 100%;
    /* background-color: antiquewhite; */
    font-size: 0.9em;
    font-weight: 300;
}

.optionsBtnDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 3%;
    background: linear-gradient(to right, #E0FFFF, #ffffff);
}

.adjustEasy2 {
    font-weight: 700;
}

.optionsBtn {
    /* background-color: red; */
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.radio-itemFirst {
   background-color: #E1E1E1;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 7px;
    border-radius: 10px;
    white-space: nowrap;
    /* cursor: pointer; */
    /* #070535 */
}

.raio-item2 {
    background-color: #070535;
    color: #E1E1E1;
}

.yourNumber {
    color: #3B49A2;
}

.radioShowLabel {

    margin-top: 3%;
}

.mainContainerDiv {
    /* background-color: antiquewhite; */
    /* display: flex; */
    /* align-items: center; */
    justify-content: flex-start;
   margin-left: 0%;
   margin-top: 2%;
   
    /* height: 100vh; */
    overflow-y: scroll;
    position: relative;
}

/* .mainContainerDiv::before {
    position: absolute;
    content: "";
    background-color: #CACACA;
    height: 100%;
    width: 5px;
    border-radius: 20px;
    left: 49%;
    transform: translateX(-50%);
   
} */

.secondDivLayer {
    /* background-color: red; */
    width: 50%;
    height: 100%;
    /* background: url("../../images/network.jfif"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    position: relative;
}

.secondDivLayer::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to right, rgba(7, 5, 53, 0.8), rgba(7, 5, 53, 0));
    z-index: 1;

}

.thirdLayerDiv {
    /* background-color: #3B49A2; */
    height: 100%;
    /* display: flex; */
    /* align-items: flex-start; */
    justify-content: flex-start;
    flex-direction: column;
    width: 70%;
    /* gap: 50px; */
}

.quiz-window-title2 {
    background-color: #3B49A2;
    color: white;
    width: 70%;
    padding: 15px;
    margin-bottom: 2%;
    font-weight: 200;
    position: relative;
}

.quiz-window-title2::before {
    content: "";
    position: absolute;
    left: -20px;
    /* Position the arrow to the left */
    top: 27%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #3B49A2;
    /* Same color as the background */
}

.checkbox-wrapper-24 {
    margin-bottom: 3%;
    margin-top: 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    /* background-color: #E1E1E1; */
}

.checkbox-wrapper-24 input[type='checkbox'] {
    appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #070535;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    position: relative;
    transition: all 2s ease;
}

.checkbox-wrapper-24 input[type='checkbox']:checked {
    background-color: #070535;
    border-color: #070535;
}

.checkbox-wrapper-24 input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 40%;
    left: 50%;
    width: 8px;
    /* Increased width */
    height: 20px;
    /* Increased height */
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    /* Centered and rotated */
}

.checkBoxDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /* background-color: #3B49A2; */
    width: 50% !important;
   
}



.reshulfleBtn button {
    background-color: #070535;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 7px;
    border: none;
    padding: 8px;
    color: white;
    cursor: pointer;
}

.vtBackIcon-icon2{
    background-color: none !important;
}

.quiz-window-body {
    background-color: #E1E1E1;
    /* height: 10%; */
}

.quiz-window-body-2 {
    background-color: #E1E1E1;
    /* height: 100%; */
}

.guiz-awards-buttons {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 100%;
}

.selectedy {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* background-color: #9f9be7; */
    padding: 10px;
    font-weight: 600;

}

.selectedAmount {
    font-size: 1.5em;
    margin-left: 5px;
    color: #3B49A2;
}

.justifySubmit button {
    background-color: #070535;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 7px;
    border: none;
    padding: 8px;
    color: white;
    cursor: pointer;
}

.button-70 {
    background-color: #070535;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 7px;
    border: none;
    padding: 5px 15px 5px 15px;
    color: white;
    cursor: pointer;
}

.button-70Def {
    background-color: #3B49A2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 7px;
    border: none;
    padding: 5px 15px 5px 15px;
    color: white;
    cursor: pointer;
}

.tableReserv {
    position: relative;
}

.tableReserv .thead-light{
    background-color: #E9ECEF;
   height: 2.5em;
   padding-left: 5em;
    /* text-align: center; */
}

.tableReserv::before {
    content: "";
    position: absolute;
    left: -20px;
    /* Position the arrow to the left */
    top: 12%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #E9ECEF;
    /* Same color as the background */
}

.selectChoice {
    /* display: grid; */
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* flex-direction: column; */
    margin-bottom: 1rem;
    
}

.selectChoice label {
    margin-bottom: 0.5rem;
    font-size: 1.1em;
    font-weight: 700;
    color: #3B49A2;
}

.secTech2 {
    padding: 10px;
    font-size: 1.1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f8f8f8;
    color: #333;
    width: 100%;
    appearance: none;
    /* Removes the default arrow in select */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="34" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
    /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
}

.secTech:focus {
    border-color: #070535;
    outline: none;
    box-shadow: 0 0 5px rgba(7, 5, 53, 0.5);
}
.container-Cart{
 padding-left: 20%;
 padding-right: 20%;
}



@media(max-width: 1700px){
    .mainContainerDiv {
        /* background-color: antiquewhite; */
        /* display: flex; */
        /* align-items: center; */
        justify-content: flex-start;
       margin-left: 8%;
       margin-top: 2%;
       
        /* height: 100vh; */
        overflow-y: scroll;
        position: relative;
    }
    .container-myForm{
        padding-left: 5%;
    }
}

@media(max-width: 1400px){
    .container2 {
       
        padding: 50px;
        width: 60%;
        margin-left: 5%;
        /* margin-top: 1%; */
        /* height: 100%; */
       
    }

    .backsetorder2 {
   
        /* height: 50vh; */
        background-image: url('../../images/RESERVE SCREEN BANNERs.png');
        background-size: cover;
         display: flex;
         background-color:  #E5E5E5 !important;
         flex-direction: column; 
         align-items: center;
         
       /* justify-content: space-between; */
        flex-wrap: wrap; 
    }


    .checkBoxDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        /* background-color: #3B49A2; */
        width: 50% !important;
       
    }
    .container-myForm{
        padding-left: 0%;
    }
    .container-Cart{
        padding-left: 15%;
        padding-right: 15%;
       }
}
@media(max-width: 993px){
    .container2 {
        background-color: #FFFFFF;
        width: 100% !important;
        margin-left: 0 !important;
       
    }
    .quiz-window-title2 {
       
        width: 100%;
    
    }
    
    .thirdLayerDiv {
      
        width: 95%;
        /* gap: 50px; */
    }
    .container-Cart{
        padding-left: 0%;
        padding-right: 5%;
       }

    .mainContainerDiv {
      
    
       margin-left: 5% !important;
       /* margin-top: -5%; */
        overflow-y: scroll;
        position: relative;
    }
    
}



@media screen and (max-width: 750px) {
    .container2 {
        width: 100%;
        padding: 0px 20px 0px 20px;
        /* overflow: hidden; */
    }
    .reserVHead2 {
        font-size: 2em;
        font-weight: 700;
      
        width: 100%;
        
    }
    .checkBoxDiv{
       
        width: 80% !important;
        font-size: 15px !important;
       
    }

    .backsetorder2{
        /* height: 100vh; */
        margin-top: 12%;
        margin-bottom: 10%;
        /* background-color: #070535; */
    }
    .yourNumber {
        color: #3B49A2;
        
    }
    

        .checkbox-wrapper-24 {
            margin-bottom: 3%;
            margin-top: 3%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 15px;
            /* background-color: #E1E1E1; */
        }

        .reserVtxt2{
            width: 100%;
        }

    .heroImage {
        display: none;
    }

    .optionsBtn {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
    }

    .adjustEasy2 {

        width: 100%;
    }

    .mainContainerDiv {
        width: 100%;
        padding: 0;
        margin-top: -0%;

    }

    .secondDivLayer {
        display: none;
    }

    .thirdLayerDiv {
        width: 100%;
    }

    .quiz-window-title2 {
        width: 90%;
    }
    .selectChoice {
        /* display: grid; */
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 90% !important;
        /* flex-direction: column; */
       
        
    }
   
}
@media(max-width: 400px){
    .mainContainerDiv {
        width: 100%;
        padding: 0;
        /* margin-top: -40%; */

    }
}

@media screen and (max-width: 1230px) {
    .heroImage {
        display: none;
    }
        
}