.cards_talk {
    max-width: 1080px;
    margin: 0 auto;
     display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
  
  .card_talk {
    background-color: dodgerblue;
    color: white;
    height: 4rem;
   
  }
  .setCArdsFlex{
    display: flex;
    justify-content: space-between;
    padding: 5px;

  }
  .toBUyBundle{
    padding: 10px;
    font-weight: 900;
  }
  